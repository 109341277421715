.loading > g > path {
	animation: pulse 1.5s ease-in-out 0.5s infinite;
    fill: #0000001a;
}

@keyframes pulse {
	0% {
		opacity: 1;
	}

	70% {
		opacity: 0.4;
	}

	100% {
		opacity: 1;
	}
}